.form-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    min-height: 40vh;
    box-sizing: border-box;
    padding: 1%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.form-modal-content>form {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 12px;
}

@media screen and (max-width: 800px) {
    .form-modal {
        width: 92vw;
        min-height: 60vh;
    }

    .form-modal-content>form {
        justify-content: space-evenly;
        gap: 22px;
    }
}
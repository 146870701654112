#view-user-page {
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

#view-user-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 14px;
    width: 60%;
}

.card-user {
    width: 80% !important;
}

@media screen and (max-width: 1200px) {
    #view-user-cards {
        flex-direction: column;
        width: 94vw;
    }
    
    .view-user-card {
        width: 100% !important;
    }

    .view-user-feedback {
        width: 94vw !important;
    }
}
#phases-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#phases-page>div {
    display: flex;
    gap: 24px;
    margin: 1%;
}

#current-phase-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#interviews-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#interviews-page>div {
    display: flex;
    gap: 24px;
    margin: 1%;
}
#bad-request-image {
    width: 80vw;
    height: auto;
}

@media screen and (min-width: 800px) {
    #bad-request-image {
        width: 30vw;
        height: auto;
    }
}
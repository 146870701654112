#homepage-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#homepage-actions>* {
    height: 500px;
}
/* 
#homepage-actions>* {
    flex: 1 0 21%;

} */
#split-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 24px;
}

#split-page>#user-list-paper {
    width: 70% !important;
}

#user-preview {
    width: 30%;
}

#user-preview #feedback-cards {
    width: 100%;
    box-sizing: content-box;
    padding-top: 700px;
}

@media only screen and (max-width: 720px) {
    #split-page {
        flex-direction: column-reverse;
        align-items: center;
        padding: 12px;
    }

    #user-preview {
        width: 98%;
    }

    #split-page>#user-list-paper {
        width: 98% !important;
    }
}
.feedback-message-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.feedback-message-header-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 4px;
}

.feedback-message-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 8px;
}

.feedback-message-content > p {
  margin-left: 8px;
}

.feedback-message-reply {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-message-text {
  width: 90%;
}

.feedback-message-comments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: 2%;
}

.questions {
  width: 100%;
  text-align: justify;
  color: gray;
  padding-left: 12px;
  white-space: pre-line;
}

.questions-and-delete-container {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .feedback-message {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .feedback-message-sender {
    font-size: 16px !important;
  }

  .feedback-message-phase {
    font-size: 12px !important;
  }
  .feedback-message-header {
    flex-direction: column;
  }
}
